import { styled } from '@liftfoils/styles'
import { Container, Link, PortableText } from '@liftfoils/components'
import { FC } from 'react'
import { SectionTextWithFeaturesProps } from '@liftfoils/models'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  $py: 'L',
  display: 'grid',
  gridGap: '$10',
  justifyItems: 'center',
})

const Title = styled('h2', {
  lift_font: 'heading01',
  textAlign: 'center',
  color: '$gray300',
  maxWidth: '600px',
  '.highlighted': {
    color: '$gray500',
  },
})

const Text = styled('div', {
  lift_font: 'heading03',
  textAlign: 'center',
  maxWidth: '600px',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  'p + p': {
    mt: '1em',
  },
})

const FeatureGrid = styled('div', {
  display: 'grid',
  gridGap: '$6',
  width: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@lg': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})
const Feature = styled('div', {
  textAlign: 'center',
  border: '1px solid',
  borderColor: '$gray200',
  borderRadius: '$r1',
  lift_font: 'heading05',
  py: '$9',
  px: '$7',
  h3: {
    color: '$gray400',
  },
  '@lg': {
    $p: 'M',
  },
})

export const SectionTextWithFeatures: FC<SectionTextWithFeaturesProps> = ({
  title,
  text,
  features,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <Container>
      <Wrap>
        {title && (
          <Title>
            <PortableText value={title} />
          </Title>
        )}

        {text && (
          <Text>
            <PortableText value={text} />
          </Text>
        )}

        {features && (
          <FeatureGrid>
            {features.map((f, i) => {
              return (
                <Feature key={i}>
                  <h3>{f.name}</h3>
                  <div>{f.value}</div>
                </Feature>
              )
            })}
          </FeatureGrid>
        )}

        {validateLink(buttonLink) && buttonLabel && (
          <Link size={'medium'} appearance={'solidGray500'} {...buttonLink}>
            {buttonLabel}
          </Link>
        )}
      </Wrap>
    </Container>
  )
}
